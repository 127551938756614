import { createApp } from 'vue';
import BannerSlider from "../../vue-components/BannerSlider.vue";

const mainParent = document.getElementById('banner-slider');

if (mainParent) {
    createApp({
        components: {
            BannerSlider
        },
    }).mount(mainParent);
}
